import React from 'react'
import Feature from './Feature'
import { useStaticQuery, graphql } from 'gatsby'
import * as styles from './features.module.scss'



export default function Features() {

  const features = useStaticQuery(getFeature).allContentfulFeature.edges
  const featureElements = features.map(feature => {
      const props = feature.node
      return (
          <Feature key = {props.title} {...props} />
      )
  })


    
    return (

        <div className = {styles.wrapper}>
            {featureElements}
        </div>
    )
}

const getFeature = graphql`
query Feature {
  allContentfulFeature(sort: {fields: displayOrder, order: ASC}) {
    edges {
      node {
        photo {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            )
        }
        description
        title
      }
    }
  }
}

`
  