import React from 'react'
import Service from '../../Service/Service'
import { BLOCKS } from '@contentful/rich-text-types'
import { renderRichText } from "gatsby-source-contentful/rich-text"
import * as styles from './style.module.scss'


const richTextOptions = {
  renderNode: {
    // disable Heading and Paragraph
    [BLOCKS.PARAGRAPH] : children => null,
    
    [BLOCKS.EMBEDDED_ENTRY] : ({ data }) => {

      const titleObj = data.target.title
      const imageUrl = data.target.image.file.url
      const description = data.target.description

      return <Service 
                { ...titleObj } 
                description = { description }
                imageUrl = { imageUrl }
              />
    }
  }
}


export default function OurServices( props ) {

  const { ourServices } = props

  return (
      <div className = {styles.container}>
          {renderRichText(ourServices,richTextOptions)}
      </div>
  )
}



