import React, {useRef, useEffect} from 'react'
import Button from '../Button/Button'
import  { gsap, Power3 } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import * as styles from './service.module.scss'

gsap.registerPlugin(ScrollTrigger)

export default function Service( props ) {
    let image = useRef(null)
    let container = useRef(null)
    let content = useRef(null)

    useEffect( ()=> {

        gsap.from([image,content],{
            scrollTrigger:{
                trigger: container,
                start: 'top 90%',
                end: 'bottom 70%',
                toggleActions: 'play none none none',
            },
            opacity: 0,
            scale:1,
            y:100,
            duration:1.5,
            ease:Power3.easeInOut,
        })

    }, [])

    return (
        <div 
          className = {styles.container} 
          ref = {el => {container = el}}
          >

            <img
              src = {props.imageUrl || ""}  
              alt = {props.title || "services"} 
              ref = {el => { image = el }}
              />

            <div className = {styles.content} ref = {el => {content = el}}>
                <h3>
                    { props.title || "Service Title" }
                </h3>
                <p>
                    {props.description || "Service Description"}  
                </p>

                {
                    props.isExternal ? 
                    <Button url = {props.url || "" } external >
                        Learn More  
                    </Button>        : 
                    <Button slug = {props.slug || "" }  >
                        Learn More  
                    </Button>   
                }

            </div>

        </div>
    )
}
