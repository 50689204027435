import React, {useEffect, useRef} from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import  { gsap, Power3 } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import * as styles from './feature.module.scss'

gsap.registerPlugin(ScrollTrigger)

export default function Feature(props) {

    let container = useRef(null)

    useEffect( ()=> {

        gsap.from(container,{
            scrollTrigger:{
                trigger: container,
                start: 'top 90%',
                end: 'bottom 70%',
                toggleActions: 'play none none none',
            },
            opacity: 0,
            scale:1,
            y:100,
            x:500,
            duration:1.5,
            ease:Power3.easeInOut,
        })

    }, [])

    const imageData = getImage(props.photo)

    // const imgPath = props.photo ? props.photo.file.url : ""
    return (
        <div className = {styles.container}
             ref = {el => { container = el }} >

            <GatsbyImage 
                image = {imageData}  
                alt = {props.title}
                className = {styles.featureImg}
                 />

            <div className = {styles.content}>
                <h3>
                    {props.title || 'Title'}
                </h3>
                <p>
                    {props.description || 'Description'}
                </p>
            </div>

        </div>
    )
}
