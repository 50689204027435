import React from 'react'
import Features from '../../components/Features'
import OurServices from '../../components/servicePage/OurServices'
import Layout from '../../layout/Layout'
import { useStaticQuery, graphql } from 'gatsby'
import * as styles from './servicePage.module.scss'



export default function Services() {
    
    // Get Page Data from Contentful
    const {contentfulServicesPage} = useStaticQuery(query)
    const ourServices = contentfulServicesPage.ourServices
    const featuresTitle = contentfulServicesPage.features.title
    const headerTitle = contentfulServicesPage.headerTitle
	const metaTitle = contentfulServicesPage.metaTitle


    return (
        <>
            <Layout title = { headerTitle || "Services & Products" } metaTitle = { metaTitle }>

              <section className = {styles.servicesPage}>
                  
                <OurServices ourServices = {ourServices}/>

                <div className = {styles.features}>
                    <h3> {featuresTitle} </h3>
                    <Features />
                </div>

              </section>   

            </Layout>
        </>
      )
}


const query = graphql`
query service {
  contentfulServicesPage {
    ourServices {
      raw
      references {
        contentful_id
        description
        image {
          file {
            url
          }
        }
        title {
          title
          displayOrder
          contentful_id
          slug
          isExternal
        }
      }
    }
    features {
      title
    }
    headerTitle
	metaTitle
  }
}
`